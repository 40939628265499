import Pui9HttpRequests from '@Pui9Requests';

export async function disableDatamodels(unit) {
	const controller = '/pmdatamodel/disableDatamodels';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		unit,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableDatamodels(unit) {
	const controller = '/pmdatamodel/enableDatamodels';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		unit,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
